<template>
  <div v-if="isShowDetail === 3">
    <Bottom>
      <template v-slot:footer>
        <div class="detail">
          <div class="top">
            <div class="top-left">
              <div
                class="icon"
                :style="{
                  border: `2px dashed ${setLineRgb(parkDetailInfo)}`,
                  background: hexToRgba(
                    parkDetailInfo?.mapData?.fenceRgb,
                    parkDetailInfo?.mapData?.fenceTransparency,
                    parkDetailInfo
                  ),
                }"
              ></div>
              <span class="title">{{ parkDetailInfo?.name }} </span>
            </div>
            <div class="top-right" @click="closeClick">
              <img src="../../../../assets/LogisticsMap/common/close-popup.png" />
            </div>
          </div>
          <div class="content">
            <div class="content-item special">
              <div class="k">
                城市：<span class="v">{{ parkDetailInfo?.proAndCity }}</span>
              </div>
              <div class="aoi-type">
                {{ parkDetailInfo?.aoiTypeName }}
              </div>
            </div>
            <div class="content-item">
              <div class="k">
                点位数量：<span class="v">{{ parkDetailInfo?.poiNum || '-' }}</span>
              </div>
            </div>
            <div v-show="parkDetailInfo?.cpLabelName" class="content-item m_t_4">
              <div class="k">
                所属企业：<span class="v">{{ parkDetailInfo?.cpLabelName || '-' }}</span>
              </div>
            </div>
          </div>
          <div v-show="isHasBtnPermission && parkDetailInfo?.aoiType === 1" class="detail-bottom">
            <div class="edit-btn" v-permission="['编辑标注']" @click="editClick">编辑</div>
          </div>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import { fenceAOIColor } from '@/utils/constantMap';

export default {
  components: {
    Bottom,
  },
  props: {
    parkDetailInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isShowDetail: 'getIsShowdetail',
      authBtn: 'getAuthBtn',
    }),
    isHasBtnPermission() {
      return this.authBtn.includes('编辑标注');
    },
  },
  methods: {
    // 十六进制转换为rgba
    hexToRgba(hex, opacity = 1, item) {
      if (!item) return;
      if (!hex && item) {
        const config = fenceAOIColor[item.aoiType];
        hex = config?.fillColor || '#69E079';
        opacity = config?.fillOpacity || 0.3;
      }
      opacity = opacity || 0.3;
      const [r, g, b] = hex.match(/\w\w/g).map((val) => parseInt(val, 16));
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    setLineRgb() {
      if (!this.parkDetailInfo) return '';
      if (this.parkDetailInfo.mapData?.lineRgb) return this.parkDetailInfo.mapData.lineRgb;
      const config = fenceAOIColor[this.parkDetailInfo.aoiType];
      return config?.strokeColor || '#44B891';
    },
    closeClick() {
      this.$store.commit('updateIsShowdetail', 0);
      this.$emit('onCloseParkDetail');
    },
    editClick() {
      this.closeClick();
      this.$emit('onEditPark');
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left {
      flex: 1;
      display: flex;
      align-items: center;
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
    .top-right {
      display: flex;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .content {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(246, 247, 248, 1);
    margin: 14px 0 0;
    .content-item {
      font-size: 14px;
      width: 100%;
      .k {
        color: rgba(156, 157, 169, 1);
      }
      .v {
        color: rgba(0, 0, 0, 1);
      }
      & + .content-item {
        margin-top: 4px;
      }
    }
    .m_t_4 {
      margin-top: 4px;
    }
    .special {
      display: flex;
      justify-content: space-between;
      align-content: center;
      box-sizing: border-box;
      .k {
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .aoi-type {
        font-size: 12px;
        border-radius: 6px;
        padding: 3px 6px;
        background: rgba(255, 229, 227, 1);
        color: rgba(250, 75, 62, 1);
        font-weight: 600;
      }
    }
  }
  .detail-bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    .edit-btn {
      width: 86px;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 79px;
      background: rgba(70, 95, 253, 1);
    }
  }
}
</style>

<template>
  <div class="point-add">
    <BackBar @click="backBtnClick" :title="editing ? '编辑点位' : '采集点位'"></BackBar>
    <Bottom isShowTool>
      <template v-slot:footer>
        <div class="detail">
          <div v-show="showPointMark" class="point-mark">
            <span>{{ poiAoiAddress }}</span>
            <img src="@/assets/LogisticsMap/picking-mark.png" alt="" />
          </div>
          <div class="content">
            <div class="warning">请拖动地图，将大头针保持在站点所在位置</div>
            <div v-if="poiInfo.poiType !== 3" class="content-item poi-parkName">
              <span>网点</span>
              <span>
                <span v-if="editing" class="selected">{{ stationInfo.parkName }}</span>
                <span v-else class="selected">{{ parkInfo.parkName }}</span>
              </span>
            </div>
            <div class="content-item poi-name">
              <span>名称</span>
              <span>
                <clear-input
                  :value.sync="poiInfo.name"
                  maxlength="30"
                  type="text"
                  placeholder="请输入站点名称"
                ></clear-input>
              </span>
            </div>
            <div>
              <div class="poi-type">
                <div class="content-item">
                  <span>点位类型</span>
                  <span v-if="editing">
                    <span class="selected">{{ poiInfo.poiTypeName }}</span>
                  </span>
                  <span v-else @click="showPoiTypePicker = true">
                    <span v-if="poiInfo.poiType" class="selected">{{ poiInfo.poiTypeName }}</span>
                    <span v-else>请选择点位类型</span>
                    <img src="@/assets/imgs/arrRight.png" alt="" />
                  </span>
                </div>
                <div
                  class="content-item"
                  style="justify-content: flex-end"
                  v-if="
                    poiInfo.poiType === 6 &&
                    ((parkInfo.serviceType || stationInfo.serviceType) === 3 ||
                      (parkInfo.serviceType || stationInfo.serviceType) === 4)
                  "
                >
                  <span class="homePoint">
                    <van-checkbox v-model="poiInfo.home">
                      <span class="homePoint-text">设置为HOME点</span>
                    </van-checkbox>
                    <van-popover
                      v-model="showPopover"
                      theme="dark"
                      trigger="click"
                      placement="top"
                      class="homePoint-popover"
                      get-container=".point-add"
                    >
                      <div class="homePoint-des">
                        请将网点停车点设置为HOME点，后续为车辆发任务时，系统会优先将其设置为任务的起终点
                      </div>
                      <template #reference>
                        <img
                          class="homePoint-trigger"
                          src="@/assets/LogisticsMap/common/question-default.png"
                        />
                      </template>
                    </van-popover>
                  </span>
                </div>
              </div>
            </div>

            <div v-if="poiInfo.poiType === 3" class="content-item poi-picList">
              <span>风险信息</span>
              <span @click="addRiskInfo">
                <span class="selected" v-if="poiInfo.poiPictures.length"
                  >{{ poiInfo.poiPictures.length }}条风险信息</span
                >
                <span v-else>点击编辑风险点信息</span>
                <img src="@/assets/imgs/arrRight.png" alt="" />
              </span>
            </div>
            <div class="content-btnGroup">
              <div
                class="content-submit"
                @click="handlePickupClick"
                :class="{ disabled: !isComplete }"
              >
                完成采集
              </div>
            </div>
          </div>
          <van-popup v-model="showPoiTypePicker" round position="bottom">
            <van-picker
              v-if="showPoiTypePicker"
              title="点位类型"
              show-toolbar
              :default-index="poiTypeIndex"
              :columns="poiTypeColumns"
              @cancel="showPoiTypePicker = false"
              @confirm="onPoiTypeConfirm"
            />
          </van-popup>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import BackBar from '@/views/LogisticsMap/components/common/BackBar.vue';
import NDialog from '@/components/NDialog/index';
import {
  createAppPoi as createPoi,
  saveOrUpdateRiskPoi,
  updateAppPoi,
  checkExistHome,
} from '@/api/apiv2';
import bus from '@/utils/bus';
import ClearInput from '../common/ClearInput.vue';

const inputRule = /^[\u4E00-\u9FA5a-zA-Z0-9-]{0,30}$/;

export default {
  components: {
    Bottom,
    BackBar,
    ClearInput,
  },
  props: ['parkInfo', 'editing', 'stationInfo'],

  data() {
    return {
      showPopover: false,
      current: 1, // 当前步骤
      queue: [], // 编辑队列
      poiInfo: {
        home: false,
        name: undefined,
        poiType: 6,
        poiTypeName: '停车点',
        poiPictures: [],
      },
      showPoiTypePicker: false,
      poiTypeIndex: 0,
      poiTypeColumns: [
        {
          text: '停车点',
          poiType: 6,
        },
        {
          text: '风险点',
          poiType: 3,
        },
      ],
      showPointMark: true,
      changed: false, // 地图动过
      poiAoiAddress: '', // 当前大头针所在的位置
    };
  },
  computed: {
    isComplete() {
      if (this.poiInfo.poiType === 3) {
        return this.poiInfo.poiPictures.length > 0;
      } else {
        return this.poiInfo.name && this.poiInfo.poiType;
      }
    },
  },
  methods: {
    addRiskInfo() {
      this.$router.push({
        name: 'riskImageUpload',
        query: { pictures: this.poiInfo.poiPictures, type: 0 },
      });
    },
    clearInput(type, ipt) {
      this.poiInfo[type] = '';
      this.$refs[ipt].focus();
    },
    backClick() {
      this.$store.commit('updateDrawStatus', 0);
      bus.emit('onChangeOverlayOptions', { common: { bubble: false } });
      if (this.editing) {
        bus.emit('onCancelEditStation');
      }
    },

    onPoiTypeConfirm(item, index) {
      this.poiInfo.poiType = item.poiType;
      this.poiInfo.poiTypeName = item.text;
      this.poiTypeIndex = index;
      this.showPoiTypePicker = false;
    },
    async handlePickupClick() {
      if (!this.isComplete) return;
      if (!inputRule.test(this.poiInfo.name)) {
        this.$toast('请不要使用特殊符号或表情');
        return;
      }
      this.$loadingCircle.start();
      const { lng, lat } = this.$options.map.getCenter();
      // let message;

      // if (!this.editing) {
      //   const checkNearby = await getStationListByCoordinate({ lat, lng });
      //   if (checkNearby.stations.length) {
      //     message = `所选位置附近${checkNearby.distance}m内存在站点，是否确定采集？`;
      //   } else {
      //     message = '确定采集站点吗？';
      //   }
      // } else {
      //   message = '确定编辑站点吗？';
      // }

      // this.$loadingCircle.end();

      // const result = await NDialog.confirm({ message });

      // if (result !== 'confirm') return;

      // this.$loadingCircle.start();
      const { name, poiType, poiPictures, home } = this.poiInfo;
      let requestFn;
      let params = {
        name,
        poiType,
        poiPictures,
        highPrecision: 0,
        showType: 1,
        lat,
        lng,
        home: ~~home,
      };
      if (poiType === 3) {
        // 风险点
        if (this.editing) {
          // 风险点编辑
          params.parkCode = this.stationInfo.parkCode;
          params.parkName = this.stationInfo.parkName;
          // params.city = this.stationInfo.city;
          // params.pro = this.stationInfo.pro;
          params.id = this.stationInfo.id;
          params.mapId = this.stationInfo.mapId;
        } else {
          params.parkCode = this.parkInfo.parkCode;
          params.parkName = this.parkInfo.parkName;
          // // todo
          // params.city = this.parkInfo.city;
          // params.pro = this.parkInfo.pro;
        }
        requestFn = saveOrUpdateRiskPoi;
      } else {
        // 停车点
        if (this.editing) {
          // 停车点编辑
          params.id = this.stationInfo.id;
          params.mapId = this.stationInfo.mapId;
          params.parkCode = this.stationInfo.parkCode;
          params.parkName = this.stationInfo.parkName;
          requestFn = updateAppPoi;
        } else {
          params.parkCode = this.parkInfo.parkCode;
          params.parkName = this.parkInfo.parkName;
          requestFn = createPoi;
        }
      }
      // 如果编辑状态，未修改位置，使用默认值
      // 如果编辑过了，需要告知后端
      if (this.editing) {
        if (!this.changed) {
          params.lat = this.stationInfo.lat;
          params.lng = this.stationInfo.lng;
        } else {
          params.dataChange = true;
        }
      }
      // 如果是风险点，需要获取地址
      if (poiType === 3) {
        if (!this.editing || this.changed) {
          const locationRes = await this.getAMapAddress([params.lng, params.lat]);
          const province = locationRes.addressComponent.province
            .replace('省', '')
            .replace('市', '');
          const city = locationRes.addressComponent.city.replace('市', '');
          params.pro = province;
          params.city = city || province;
          params.district = locationRes.addressComponent.district;
        } else {
          params.pro = this.stationInfo.pro;
          params.city = this.stationInfo.city;
          params.district = this.stationInfo.district;
        }
      } else {
        const regeocode = await this.getAMapAddress([params.lng, params.lat]);
        const _poisLength = regeocode.pois.length;
        let address = null;
        if (_poisLength > 0) {
          for (let i = 0; i < regeocode.pois.length; i++) {
            let reAddress = regeocode.pois[i].address;
            if (typeof reAddress === 'string' && reAddress) {
              address = reAddress;
              break;
            }
          }
        }
        if (address) {
          params.address = address;
        } else {
          params.address = regeocode.formatted_address;
        }
      }
      // if (this.poiInfo.poiType === 6 && this.poiInfo.home) {
      //   this.$loadingCircle.end();
      //   const assureRes = await checkExistHome({
      //     id: params.id,
      //     parkCode: this.parkInfo.parkCode,
      //   });
      //   if (assureRes && assureRes !== this.poiInfo.name) {
      //     const result = await NDialog.confirm({
      //       title: '提示',
      //       message: `此网点已存在HOME点，名称：<span style="color: rgb(70, 95, 253)">${assureRes}</span> , 是否继续将 <span style="color: rgb(70, 95, 253)">${this.poiInfo.name}</span> 设置为HOME点？`,
      //       okText: '确定设置',
      //       cancelText: '暂不设置',
      //     });
      //     if (result !== 'confirm') {
      //       params.home = 0;
      //     }
      //   }
      // }

      let assureRes, message;

      if (this.poiInfo.poiType === 6 && this.poiInfo.home) {
        this.$loadingCircle.end();
        assureRes = await checkExistHome({
          id: params.id,
          parkCode: this.parkInfo.parkCode,
        });
      }

      if (assureRes && assureRes !== this.poiInfo.name) {
        const result = await NDialog.confirm({
          title: '提示',
          message: `此网点已存在HOME点，名称：<span style="color: rgb(70, 95, 253)">${assureRes}</span> , 是否继续将 <span style="color: rgb(70, 95, 253)">${this.poiInfo.name}</span> 设置为HOME点？`,
          okText: '确定设置',
          cancelText: '暂不设置',
        });
        if (result !== 'confirm') {
          params.home = 0;
        }
      } else {
        if (!this.editing) {
          message = '确定采集站点吗？';
        } else {
          message = '确定编辑站点吗？';
        }

        this.$loadingCircle.end();

        const result = await NDialog.confirm({ message });

        if (result !== 'confirm') return;

        this.$loadingCircle.start();
      }

      const res = await requestFn(params).catch(() => {});
      this.$loadingCircle.end();
      if (res) {
        const result = {
          ...res,
          poiType: this.poiInfo.poiType,
          isEditing: this.editing,
          mapType: 'POI',
          mapData: {
            lng: res.lng, //经度
            lat: res.lat, //维度
          },
        };
        if (this.poiInfo.poiType === 3) {
          result.pictureList = poiPictures;
        }
        // 如果绑定了线路，需要提示
        if (res.bindRoute && res.bindRoute.length > 0) {
          const message =
            '检测到POI位置或朝向发生了变化,请重新规划与该POI关联的线路,具体如下:<br />' +
            res.bindRoute.join('、');
          NDialog.confirm({ title: '提示', message, isHideCancel: true });
        } else {
          if (!this.editing) {
            this.$toast('站点采集成功');
          } else {
            this.$toast('操作成功');
          }
        }
        setTimeout(() => {
          bus.emit('onAddNewMarker', result);
        }, 10);
        this.backClick();
      }
    },
    listenKeyboardUp() {
      let initialHeight = window.innerHeight;
      const controller = new AbortController();
      const signal = controller.signal;
      window.addEventListener(
        'resize',
        () => {
          const currentHeight = window.innerHeight;

          if (currentHeight < initialHeight - 3) {
            this.showPointMark = false;
          } else {
            this.showPointMark = true;
          }
        },
        { signal: signal }
      );
      return controller;
    },
    onAddRiskInfo(data) {
      this.poiInfo.poiPictures = data;
    },
    getAMapAddress(lnglat) {
      return new Promise((resolve, reject) => {
        this.$options.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.regeocode) {
            resolve(result.regeocode);
          } else {
            reject();
          }
        });
      });
    },

    // 获取当前地图中心点的地址信息
    async getStationAMapAddress() {
      const { lng, lat } = this.$options.map.getCenter();
      const locationRes = await this.getAMapAddress([lng, lat]);
      let address = locationRes.aois?.[0]?.name ?? locationRes.formattedAddress;
      this.poiAoiAddress = address;
    },
    mapMoveend() {
      if (!this.$store.state.LogisticsMap.lockGestureMap) {
        this.changed = true;
        this.getStationAMapAddress();
      }
    },
    initData() {
      // 如果是编辑状态
      if (this.editing) {
        const { name, poiType, pictureList = [], home } = this.stationInfo;
        const index = this.poiTypeColumns.findIndex((item) => item.poiType === poiType);
        Object.assign(this.poiInfo, {
          home: Boolean(home),
          name,
          poiType,
          poiTypeName: this.poiTypeColumns[index].text,
          poiPictures: pictureList,
        });
        this.poiTypeIndex = index;
      }
      this.getStationAMapAddress();
      this.$options.map.on('moveend', this.mapMoveend);
    },
    async backBtnClick() {
      const result = await NDialog.confirm({
        title: '确定取消绘制吗?',
        message: '确定后,刚才绘制和填写的数据均不会保存,请谨慎操作!',
      });
      if (result === 'confirm') {
        this.backClick();
      }
    },
  },
  created() {
    this.$options.map = window.AMap.lcs.map;
    this.$options.siginal = this.listenKeyboardUp();
    this.$options.geoCoder = new window.AMap.Geocoder({
      radius: 200,
      batch: false,
      extensions: 'all',
    }); // 初始化地理编码器，查询地址时需要
    // 数据初始化
    this.initData();
  },
  mounted() {
    bus.on('onAddRiskInfo', this.onAddRiskInfo);
  },

  beforeDestroy() {
    this.$options.siginal.abort();
    this.$options.map.off('moveend', this.mapMoveend);
    bus.off('onAddRiskInfo', this.onAddRiskInfo);
  },
};
</script>
<style lang="scss">
// .content-item:has(.homePoint) {
//   justify-content: flex-end;
// }

.homePoint {
  width: max-content !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  .homePoint-text {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
  }

  .homePoint-trigger {
    width: 16px;
    height: 16px;
    margin-left: 6px;
    margin-bottom: -2px;
    margin-right: 10px;
  }
}

.point-add {
  .van-popup {
    left: unset !important;
    right: 9px;
    .van-popover__arrow {
      right: 14px !important;
      left: unset !important;
    }
    .homePoint-des {
      user-select: none;
      width: 230px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      padding: 10px 12px;
    }
  }
}
</style>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
}

.poi-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4%;
  .content-item {
    flex: 1 0 48%;
  }
}

.point-mark {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -100%);
  z-index: 9;

  span {
    font-size: 12px;
    color: #222222;
    font-weight: bold;
    display: block;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 4px 12px;
    margin-bottom: 4px;
    width: max-content;
  }

  img {
    width: 28px;
    height: 40px;
  }
}

.content {
  .warning {
    background: #fef2ea;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    color: #fe8000;
    padding: 0.17rem 0.2rem;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 0.24rem;
    font-weight: bold;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    &.poi-parkName {
      padding-top: 8px;
    }

    > span {
      &:nth-child(1) {
        width: 80px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(151, 152, 171, 1);
      }
      &:nth-child(2) {
        position: relative;
        flex: 1;
        > span {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(151, 152, 171, 1);
          &.selected {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
          }
        }
        input {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(151, 152, 171, 1);
          }
        }
        img {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  &-btnGroup {
    margin-top: 12px;
    display: flex;
    align-items: center;

    .content-submit {
      flex-grow: 1;
      box-sizing: border-box;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      &.disabled {
        opacity: 0.2;
      }
      &:not(.disabled) {
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
</style>

<template>
  <div v-if="isShowDetail === 2">
    <Bottom :isShowTool="true">
      <template v-slot:footer>
        <div class="detail">
          <div class="top">
            <div class="top-left">
              <svg
                t="1729238173556"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5251"
              >
                <path
                  d="M972.145778 332.088889a51.114667 51.114667 0 0 1-3.072 64.881778 50.944 50.944 0 0 1-9.102222 7.964444L601.6 671.857778a114.602667 114.602667 0 0 1-72.789333 22.897778 114.858667 114.858667 0 0 1-71.054223-27.448889l-132.693333-113.095111a12.344889 12.344889 0 0 0-7.651555-2.986667 12.629333 12.629333 0 0 0-8.021334 2.332444l-184.689778 130.161778a50.716444 50.716444 0 0 1-25.344 10.581333 51.427556 51.427556 0 0 1-37.774222-10.410666 51.086222 51.086222 0 0 1-19.000889-49.095111 51.057778 51.057778 0 0 1 20.992-33.166223l-0.028444-0.056888 186.88-131.697778a114.972444 114.972444 0 0 1 71.480889-20.935111 114.403556 114.403556 0 0 1 69.290666 27.107555l132.977778 113.322667c2.275556 1.934222 4.949333 2.958222 7.992889 3.043555 3.015111 0.113778 5.688889-0.711111 7.992889-2.474666l360.248889-268.344889 0.028444 0.056889 0.056889-0.056889a51.029333 51.029333 0 0 1 42.951111-8.618667 51.2 51.2 0 0 1 28.700445 19.114667z"
                  p-id="5252"
                ></path>
              </svg>
              <span class="title">{{ routeInfo?.subwayName }} </span>
            </div>
            <div class="top-right" @click="closeClick">
              <img src="../../../../assets/LogisticsMap/common/close-popup.png" />
            </div>
          </div>
          <div class="content">
            <div class="content-item special">
              <div class="k">
                网点：<span class="v">{{ routeInfo?.parkName }}</span>
              </div>
              <div class="route-type">
                <span class="status" :class="{ stopColor: routeInfo?.usingState === 0 }">{{
                  routeInfo?.usingState === 1 ? '启用' : '停用'
                }}</span>
                <span class="route">{{
                  routeInfo?.highPrecision === 1 ? '高精线路' : '普通线路'
                }}</span>
              </div>
            </div>
            <div class="content-item m_b_4">
              <div class="k">
                长度：<span class="v">{{ computedDistance() }}</span>
              </div>
            </div>
            <div class="station_info">
              <div class="k">站点信息：</div>
              <div class="v">
                <div>{{ stationInfo() }}</div>
              </div>
            </div>
            <div v-show="routeInfo?.cpLabelName" class="content-item m_t_4">
              <div class="k">
                所属企业：：<span class="v">{{ routeInfo?.cpLabelName }}</span>
              </div>
            </div>
          </div>
          <div class="detail-bottom" v-show="isHasBtnPermission">
            <div
              v-permission="['删除标注']"
              v-show="routeInfo?.usingState === 0"
              class="route-status stop"
              @click="deleteClick"
            >
              删除
            </div>
            <div
              v-permission="['停用/启用路线']"
              class="route-status"
              :class="{ stop: routeInfo?.usingState === 1 }"
              @click="stopClick"
            >
              {{ routeInfo?.usingState === 1 ? '停用' : '启用' }}
            </div>
            <div v-permission="['编辑标注']" class="edit-btn" @click="editClick">编辑</div>
          </div>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import { deleteRouteById, modifyRouteState } from '@/api/api';
import bus from '@/utils/bus';

export default {
  components: {
    Bottom,
  },
  props: {
    routeInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isShowDetail: 'getIsShowdetail',
      authBtn: 'getAuthBtn',
    }),
    isHasBtnPermission() {
      return (
        this.authBtn.includes('编辑标注') &&
        (this.authBtn.includes('停用/启用路线') || this.authBtn.includes('删除标注'))
      );
    },
    toolBottom() {
      if (this.isHasBtnPermission) {
        if (this.routeInfo?.cpLabelName) return '268px';
        return '248px';
      }
      return '222px';
    },
  },
  methods: {
    closeClick() {
      // 关闭
      this.$store.commit('updateIsShowdetail', 0);
      this.$emit('onCloseRouteDetail');
    },
    editClick() {
      this.$emit('onEditRoute');
    },
    async stopClick() {
      const result = await NDialog.confirm({
        title: this.routeInfo.usingState === 1 ? '确定停用该线路吗？' : '确定启用该线路吗？',
        message:
          this.routeInfo.usingState === 1
            ? '停用后，线路中绑定的车辆或格口将被解绑，可能影响线上业务，请谨慎操作！'
            : '启用后，线路将在应用层露出并允许被使用，请谨慎操作！',
      });
      if (result === 'confirm') {
        const res = await modifyRouteState({
          routeId: this.routeInfo.routeId,
          usingState: this.routeInfo.usingState === 1 ? 0 : 1,
        });
        this.$toast('操作成功');
        this.$emit('onUpdateUsingState', this.routeInfo.usingState);
      }
    },
    async deleteClick() {
      const result = await NDialog.confirm({
        title: '确定删除该数据吗？',
        message: '数据删除可能影响线上业务，请谨慎操作',
      });
      if (result === 'confirm') {
        const res = await deleteRouteById({
          routeId: this.routeInfo.routeId,
        });
        if (res.code === '10000') {
          this.$toast('操作成功');
          this.$emit('onDeleteRoute');
        } else {
          this.$toast(res.msg);
        }
      }
    },
    computedmotorDistance(value) {
      if (value > 1000) {
        return `${(value / 1000).toFixed(2)} km`;
      }
      return `${value} m`;
    },
    computedDistance() {
      if (!this.routeInfo) return;
      const total = this.computedmotorDistance(this.routeInfo.routeDistance);
      let motorway = 0;
      if (this.routeInfo.motorwayDistance) {
        motorway = this.computedmotorDistance(this.routeInfo.motorwayDistance);
      } else {
        let len = 0,
          _temp = [],
          line = this.routeInfo?.vehicleLaneLine;
        if (line) {
          _temp = JSON.parse(line);
          _temp.forEach((lineArr) => {
            lineArr.forEach((motorway) => {
              len += AMap.GeometryUtil.distanceOfLine(motorway);
            });
          });
        }
        if (this.routeInfo.routeDistance && len > this.routeInfo.routeDistance) {
          // 防止累加过程,出现小数点四舍五收入大于道路的长度
          len = this.routeInfo?.routeDistance;
        } else {
          len = Math.round(len);
        }
        motorway = this.computedmotorDistance(len);
      }

      return `总长 ${total}  机动车道 ${motorway}`;
    },
    stationInfo() {
      if (!this.routeInfo) return;
      const stationArr = [];
      for (const station of this.routeInfo.pilotSubWayRoutePaths) {
        stationArr.push(station.stationName);
      }
      return stationArr.join(' 至 ');
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left {
      flex: 1;
      display: flex;
      align-items: center;
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        fill: rgba(255, 55, 0, 1);
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
    .top-right {
      display: flex;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .content {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(246, 247, 248, 1);
    margin: 14px 0 0;
    .content-item {
      display: flex;
      font-size: 14px;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /* 这里是超出几行省略 */
      overflow: hidden;
      .k {
        color: rgba(156, 157, 169, 1);
      }
      .v {
        color: rgba(0, 0, 0, 1);
      }
    }
    .m_b_4 {
      margin-bottom: 4px;
    }
    .m_t_4 {
      margin-top: 4px;
    }
    .station_info {
      display: flex;
      font-size: 14px;
      width: 100%;

      .k {
        color: rgba(156, 157, 169, 1);
        // padding-bottom: 12px;
        box-sizing: border-box;
      }
      .v {
        color: rgba(0, 0, 0, 1);
        flex: 1;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        // padding-bottom: 12px;
        box-sizing: border-box;
      }
    }
    .special {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .k {
        flex: 1;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; /* 限制不允许换行 */
      }
      .route-type {
        width: max-content;
        font-size: 12px;
        font-weight: 600;
        .status {
          display: inline-block;
          padding: 3px 6px;
          background: rgba(235, 235, 235, 1);
          color: rgba(70, 95, 253, 1);
          margin-right: 4px;
          border-radius: 6px;
          &.stopColor {
            color: rgba(255, 45, 85, 1);
          }
        }
        .route {
          display: inline-block;
          padding: 3px 6px;
          background: rgba(219, 245, 196, 1);
          color: rgba(36, 181, 109, 1);
          border-radius: 6px;
        }
      }
    }
  }
  .detail-bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    .route-status {
      width: 86px;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 79px;
      border: 1px solid rgba(70, 95, 253, 1);
      color: rgba(70, 95, 253, 1);
      margin-left: 6px;
    }
    .stop {
      border: 1px solid rgba(255, 45, 85, 1);
      color: rgba(255, 45, 85, 1);
    }
    .edit-btn {
      width: 86px;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 79px;
      background: rgba(70, 95, 253, 1);
      margin-left: 6px;
    }
  }
}
</style>

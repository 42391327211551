<template>
  <div class="point-add">
    <BackBar @click="backBtnClick" :title="editing ? '编辑点位' : '采集点位'"></BackBar>
    <Bottom isShowTool>
      <template v-slot:footer>
        <div class="detail">
          <div
            v-show="showPointMark && !(editing && highPrecisionPhase === 1 && !changed)"
            class="point-mark"
          >
            <span v-if="highPrecisionPhase === 0">{{ poiAoiAddress }}</span>
            <span v-else>拖动地图确定朝向</span>

            <img src="@/assets/LogisticsMap/picking-mark.png" alt="" />
          </div>
          <div class="content">
            <div class="warning" v-if="!(editing && highPrecisionPhase === 1 && !changed)">
              <span v-if="highPrecisionPhase === 0">
                请拖动地图，将大头针保持在高精地图车道线上
              </span>
              <span v-else>请拖动地图确定行驶方向，无人车将按朝向行驶</span>
            </div>
            <div class="content-item poi-parkName">
              <span>网点</span>
              <span>
                <span v-if="!editing" class="selected">{{ parkInfo.parkName }}</span>
                <span v-else class="selected">{{ stationInfo.parkName }}</span>
              </span>
            </div>
            <div v-if="highPrecisionPhase === 1" class="content-item poi-name">
              <span>名称</span>
              <span>
                <clear-input
                  :value.sync="poiInfo.name"
                  maxlength="30"
                  type="text"
                  placeholder="请输入站点名称"
                ></clear-input>
              </span>
            </div>
            <div class="poi-type">
              <div class="content-item">
                <span>点位类型</span>
                <span>
                  <span class="selected">停车点</span>
                </span>
              </div>
              <div
                v-if="
                  highPrecisionPhase === 1 &&
                  ((parkInfo.serviceType || stationInfo.serviceType) === 3 ||
                    (parkInfo.serviceType || stationInfo.serviceType) === 4)
                "
                class="content-item"
                style="justify-content: flex-end"
              >
                <span class="homePoint">
                  <van-checkbox v-model="poiInfo.home">
                    <span class="homePoint-text">设置为HOME点</span>
                  </van-checkbox>
                  <van-popover
                    v-model="showPopover"
                    theme="dark"
                    trigger="click"
                    placement="top"
                    class="homePoint-popover"
                    get-container=".point-add"
                  >
                    <div class="homePoint-des">
                      请将网点停车点设置为HOME点，后续为车辆发任务时，系统会优先将其设置为任务的起终点
                    </div>
                    <template #reference>
                      <img
                        class="homePoint-trigger"
                        src="@/assets/LogisticsMap/common/question-default.png"
                      />
                    </template>
                  </van-popover>
                </span>
              </div>
            </div>
            <div v-if="highPrecisionPhase === 0" class="content-btnGroup">
              <div
                class="content-submit"
                v-if="changed || !editing"
                @click="handlePickupHighPrClick"
              >
                确认点位位置
              </div>
              <div class="content-submit" v-else-if="highPrecisionPhase === 0" @click="skipPickup">
                跳过
              </div>
            </div>
            <div v-if="highPrecisionPhase === 1" class="step2-btnGroup">
              <div class="prev-btn" @click="highPrecisionPrevPhase">上一步</div>
              <div
                class="step2-submit"
                @click="highPrecisionPickup"
                :class="{ disabled: !isComplete }"
              >
                完成设置
              </div>
            </div>
          </div>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import BackBar from '@/views/LogisticsMap/components/common/BackBar.vue';
import NDialog from '@/components/NDialog/index';
import {
  coordinateTransform,
  createAppPoi as createPoi,
  updateAppPoi,
  checkExistHome,
} from '@/api/apiv2';
import { getAngle, getYaw } from '@/utils/index';
import { zIndexMax } from '@/utils/constantMap';

import bus from '@/utils/bus';
import ClearInput from '../common/ClearInput.vue';

const inputRule = /^[\u4E00-\u9FA5a-zA-Z0-9-]{0,30}$/;

export default {
  components: {
    Bottom,
    BackBar,
    ClearInput,
  },
  props: ['parkInfo', 'editing', 'stationInfo'],
  data() {
    return {
      showPopover: false,
      highPrecisionPhase: 0, // 当前步骤
      highPrecisionDiabled: true,
      mapMoved: false,
      queue: [], // 编辑队列
      poiInfo: {
        name: undefined,
        poiType: 6,
        poiTypeName: '停车点',
        poiPictures: [],
        home: false,
      },
      pickupInfo: {
        open: false,
        enabled: false, // 该点是否可采集
        stationName: '',
        coordinate: null,
      },
      showPointMark: true,
      changed: false, // 地图动过
      poiAoiAddress: '', // 当前大头针所在的位置
    };
  },
  computed: {
    isComplete() {
      return (
        this.poiInfo.poiType &&
        this.poiInfo.name &&
        (this.mapMoved || (this.editing && !this.changed))
      );
    },
  },
  methods: {
    removeOverlayAfterDraw() {
      this.$options.map.off('moveend', this.pickUpStart);
      this.$options.map.off('moveend', this.mapMoveend);
      if (this.$options.highPrecisionBaseMarker) {
        this.$options.map.remove(this.$options.highPrecisionBaseMarker);
        this.$options.map.remove(this.$options.directionLine);
        this.$options.map.remove(this.$options.directionArrowMarker);
      }
    },
    backClick() {
      this.$store.commit('updateDrawStatus', 0);
      bus.emit('onChangeOverlayOptions', { common: { bubble: false } });
      this.removeOverlayAfterDraw();
      if (this.editing) {
        bus.emit('onCancelEditStation');
      }
    },

    prevClick() {
      this.highPrecisionPhase = 0;
      bus.emit('onHighMarkerVisible', 0);
    },

    getAMapAddress(lnglat) {
      return new Promise((resolve, reject) => {
        this.$options.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.regeocode) {
            resolve(result.regeocode);
          } else {
            reject();
          }
        });
      });
    },
    initHighStationDraw() {
      // 高精采集基点
      this.$options.highPrecisionBaseMarker = new window.AMap.Marker({
        position: [0, 0],
        visible: false,
        offset: [-5, -5],
        zIndex: zIndexMax,
        icon: new window.AMap.Icon({
          size: [10, 10],
          image: './assets/images/high_base.png',
          imageSize: [10, 10],
        }),
      });

      this.$options.map.add(this.$options.highPrecisionBaseMarker);

      // 方向箭头
      this.$options.directionArrowMarker = new window.AMap.Marker({
        position: [0, 0],
        visible: false,
        offset: [-4, -6],
        zIndex: zIndexMax,
        icon: new window.AMap.Icon({
          size: [8, 12],
          image: './assets/images/direction_arrow.png',
          imageSize: [8, 12],
        }),
      });

      this.$options.map.add(this.$options.directionArrowMarker);

      // 方向线
      this.$options.directionLine = new window.AMap.Polyline({
        path: [[0, 0]],
        zIndex: zIndexMax,
        strokeColor: '#EF2F41',
        strokeWeight: 2,
        strokeStyle: 'dashed',
        strokeOpacity: 1,
        strokeDasharray: [10, 2],
      });

      this.$options.map.add(this.$options.directionLine);
    },
    pickUpStart(prop) {
      // 如果地图移动过， 取消disabled
      if (prop && this.highPrecisionPhase === 1) {
        this.mapMoved = true;
      }
      const center = this.$options.map.getCenter();
      this.pickupInfo.coordinate = center;

      this.pickupInfo.enabled = true;
      if (this.highPrecisionPhase === 1) {
        const end = this.$options.map.getCenter();
        const start = this.$options.highPrecisionBaseMarker.getPosition();
        this.highPrecisionStart = start;
        this.highPrecisionEnd = end;
        const angle = getAngle(start, end);
        this.$options.directionArrowMarker.setPosition(end);
        this.$options.directionArrowMarker.setAngle(angle);
        this.$options.directionArrowMarker.show();
        this.$options.directionLine.setPath([start, end]);
        this.$options.directionLine.show();
      }
    },
    // 确定POI
    handlePickupHighPrClick() {
      const mapCenter = this.$options.map.getCenter();
      this.$options.map.on('moveend', this.pickUpStart);
      // todo 确定当前点，画方向
      this.$options.highPrecisionBaseMarker.setPosition(mapCenter);
      this.$options.highPrecisionBaseMarker.show();
      this.highPrecisionPhase = 1;
    },
    // 高精采集过程中，点击上一步
    highPrecisionPrevPhase() {
      bus.emit('onHighMarkerVisible', 0);
      this.highPrecisionPhase = 0;
      this.mapMoved = false;
      // this.highPrecisionDiabled = true;
      this.$options.directionArrowMarker?.hide();
      this.$options.directionLine?.hide();
      this.$options.highPrecisionBaseMarker?.hide();
    },

    // 高精采集完成
    async highPrecisionPickup() {
      if (!this.isComplete) return;
      if (!inputRule.test(this.poiInfo.name)) {
        this.$toast('请不要使用特殊符号或表情');
        return;
      }
      let requestFn, params, coordinate;
      const { name, poiType } = this.poiInfo;
      if (!this.editing || this.changed) {
        this.$loadingCircle.start();
        const startArr = [this.highPrecisionStart.lng, this.highPrecisionStart.lat];
        const endArr = [this.highPrecisionEnd.lng, this.highPrecisionEnd.lat];

        let highCoordinate;

        const coordRes = await coordinateTransform({
          x: startArr[0],
          y: startArr[1],
        });
        if (coordRes?.data?.utm) {
          highCoordinate = coordRes.data.utm;
        } else {
          this.$toast('未获取到zone值，点位必须在高精地图内');
          this.$loadingCircle.end();
          return;
        }
        this.$loadingCircle.start();

        const heading = getYaw([startArr, endArr]);

        const yaw = [Math.cos(heading * 0.5), 0, 0, -Math.sin(heading * 0.5)];

        const { lng, lat } = this.highPrecisionStart;
        // let message;

        // if (!this.editing) {
        //   const checkNearby = await getStationListByCoordinate({ lat, lng });
        //   if (checkNearby.stations.length) {
        //     message = `所选位置附近${checkNearby.distance}m内存在站点，是否确定采集？`;
        //   } else {
        //     message = '确定采集站点吗？';
        //   }
        // } else {
        //   message = '确定编辑站点吗？';
        // }

        // this.$loadingCircle.end();

        // const result = await NDialog.confirm({ message });

        // if (result !== 'confirm') return;

        // this.$loadingCircle.start();
        params = {
          parkCode: this.parkInfo.parkCode,
          parkName: this.parkInfo.parkName,
          name,
          poiType,
          highPrecision: 1,
          showType: 1,
        };
        if (this.editing) {
          requestFn = updateAppPoi;
          params.id = this.stationInfo.id;
          if (this.changed) {
            coordinate = [lng, lat];
            params.dataChange = true;
            Object.assign(params, {
              qx: yaw[1],
              qy: yaw[2],
              qz: yaw[3],
              qw: yaw[0],
              heading,
              lat: highCoordinate[0], // 后端接口反了，所以反着来
              lng: highCoordinate[1],
              originalCoordinates: `${lng},${lat}`,
              parkCode: this.stationInfo.parkCode,
              aoiType: this.stationInfo.aoiType,
            });
          }
        } else {
          requestFn = createPoi;
          coordinate = [lng, lat];
          Object.assign(params, {
            qx: yaw[1],
            qy: yaw[2],
            qz: yaw[3],
            qw: yaw[0],
            heading,
            id: null,
            lat: highCoordinate[0], // 后端接口反了，所以反着来
            lng: highCoordinate[1],
            originalCoordinates: `${lng},${lat}`,
          });
        }
      } else {
        params = {
          address: this.stationInfo.address,
          id: this.stationInfo.id,
          parkCode: this.stationInfo.parkCode,
          parkName: this.stationInfo.parkName,
          name,
          poiType,
          highPrecision: 1,
          showType: 1,
          dataChange: false,
          qx: this.stationInfo.qx,
          qy: this.stationInfo.qy,
          qz: this.stationInfo.qz,
          qw: this.stationInfo.qw,
          heading: this.stationInfo.heading,
          lat: this.stationInfo.pos?.[0] || this.stationInfo.lat,
          lng: this.stationInfo.pos?.[1] || this.stationInfo.lng,
          originalCoordinates: `${this.stationInfo.lng},${this.stationInfo.lat}`,
        };
        requestFn = updateAppPoi;
      }
      // 若果新增或者修改了，需要重新获取地址
      if (!this.editing || this.changed) {
        const regeocode = await this.getAMapAddress(coordinate);
        const _poisLength = regeocode.pois.length;
        let address = null;
        if (_poisLength > 0) {
          for (let i = 0; i < regeocode.pois.length; i++) {
            let reAddress = regeocode.pois[i].address;
            if (typeof reAddress === 'string' && reAddress) {
              address = reAddress;
              break;
            }
          }
        }
        if (address) {
          params.address = address;
        } else {
          params.address = regeocode.formatted_address;
        }
      }

      params.home = ~~this.poiInfo.home;

      let assureRes, message;

      if (this.poiInfo.poiType === 6 && this.poiInfo.home) {
        this.$loadingCircle.end();
        assureRes = await checkExistHome({
          id: params.id,
          parkCode: this.parkInfo.parkCode,
        });
      }

      if (assureRes && assureRes !== this.poiInfo.name) {
        const result = await NDialog.confirm({
          title: '提示',
          message: `此网点已存在HOME点，名称：<span style="color: rgb(70, 95, 253)">${assureRes}</span> , 是否继续将 <span style="color: rgb(70, 95, 253)">${this.poiInfo.name}</span> 设置为HOME点？`,
          okText: '确定设置',
          cancelText: '暂不设置',
        });
        if (result !== 'confirm') {
          params.home = 0;
        }
      } else {
        if (!this.editing) {
          message = '确定采集站点吗？';
        } else {
          message = '确定编辑站点吗？';
        }

        this.$loadingCircle.end();

        const result = await NDialog.confirm({ message });

        if (result !== 'confirm') return;

        this.$loadingCircle.start();
      }

      const res = await requestFn(params).catch(() => {});
      this.$loadingCircle.end();
      if (res) {
        if (res.bindRoute && res.bindRoute.length > 0) {
          const message =
            '检测到POI位置或朝向发生了变化,请重新规划与该POI关联的线路,具体如下:<br />' +
            res.bindRoute.join('、');
          NDialog.confirm({ title: '提示', message, isHideCancel: true });
        } else {
          if (!this.editing) {
            this.$toast('站点采集成功');
          } else {
            this.$toast('操作成功');
          }
        }
        const [newLng, newLat] = res.originalCoordinates.split(',');
        bus.emit('onAddNewMarker', {
          ...res,
          isEditing: this.editing,
          selfType: res.poiType,
          mapType: 'POI',
          mapData: {
            lng: newLng, //经度
            lat: newLat, //维度
          },
        });
        this.backClick();
      }
    },
    listenKeyboardUp() {
      let initialHeight = window.innerHeight;
      const controller = new AbortController();
      const signal = controller.signal;
      window.addEventListener(
        'resize',
        () => {
          const currentHeight = window.innerHeight;
          // 键盘收起ios高度会偏差所以-3
          if (currentHeight < initialHeight - 3) {
            this.showPointMark = false;
          } else {
            this.showPointMark = true;
          }
        },
        { signal: signal }
      );
      return controller;
    },
    skipPickup() {
      bus.emit('onHighMarkerVisible', 1);
      this.highPrecisionPhase = 1;
    },
    // 获取当前地图中心点的地址信息
    async getStationAMapAddress() {
      const { lng, lat } = this.$options.map.getCenter();
      const locationRes = await this.getAMapAddress([lng, lat]);
      let address = locationRes.aois?.[0]?.name ?? locationRes.formattedAddress;
      this.poiAoiAddress = address;
    },
    mapMoveend() {
      if (!this.$store.state.LogisticsMap.lockGestureMap && this.highPrecisionPhase === 0) {
        this.changed = true;
      }
      this.getStationAMapAddress();
    },
    initData() {
      // 如果是编辑状态
      if (this.editing) {
        const { name, home } = this.stationInfo;
        Object.assign(this.poiInfo, {
          name,
          home: Boolean(home),
        });
      }
      this.getStationAMapAddress();
      this.$options.map.on('moveend', this.mapMoveend);
    },
    async backBtnClick() {
      const result = await NDialog.confirm({
        title: '确定取消绘制吗?',
        message: '确定后,刚才绘制和填写的数据均不会保存,请谨慎操作!',
      });
      if (result === 'confirm') {
        this.backClick();
      }
    },
  },
  watch: {
    changed(newValue) {
      newValue && this.initHighStationDraw();
    },
  },
  created() {
    this.$options.map = window.AMap.lcs.map;
    this.$options.geoCoder = new window.AMap.Geocoder({
      radius: 200,
      batch: false,
      extensions: 'all',
    }); // 初始化地理编码器，查询地址时需要
    this.initData();
  },

  mounted() {
    // this.$options.geoCoder = new window.AMap.Geocoder({ radius: 200 }); // 初始化地理编码器，查询地址时需要
    if (!this.editing) this.initHighStationDraw();
    this.$options.siginal = this.listenKeyboardUp();
  },
  beforeDestroy() {
    this.$options.siginal.abort();
    this.$options.map.off('moveend', this.mapMoveend);
  },
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
}

.poi-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  .content-item {
    flex: 1 0 auto;
  }
}

.point-mark {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -100%);
  z-index: 9;

  span {
    font-size: 12px;
    color: #222222;
    font-weight: bold;
    display: block;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 4px 12px;
    margin-bottom: 4px;
    width: max-content;
  }

  img {
    width: 28px;
    height: 40px;
  }
}

.content {
  .warning {
    background: #fef2ea;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    color: #fe8000;
    padding: 0.17rem 0.2rem;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 0.24rem;
    font-weight: bold;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    &.poi-parkName {
      padding-top: 8px;
    }

    > span {
      &:nth-child(1) {
        width: 80px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(151, 152, 171, 1);
      }
      &:nth-child(2) {
        position: relative;
        flex: 1;
        > span {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(151, 152, 171, 1);
          &.selected {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
          }
        }
        input {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(151, 152, 171, 1);
          }
        }
        img {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  &-btnGroup {
    margin-top: 12px;
    display: flex;
    align-items: center;

    .content-submit {
      flex-grow: 1;
      box-sizing: border-box;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      &.disabled {
        opacity: 0.2;
      }
    }
  }

  .step2-btnGroup {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prev-btn {
      flex-grow: 1;
      box-sizing: border-box;
      height: 46px;
      opacity: 1;
      border-radius: 23px;
      border: 2px solid rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(70, 95, 253, 1);
      text-align: center;
    }

    .step2-submit {
      flex-grow: 2;
      box-sizing: border-box;
      height: 46px;
      opacity: 1;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      &.disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>

<template>
  <div class="searchPanel">
    <div class="searchBar">
      <div class="searchBarInner">
        <div class="inputWrapper">
          <img class="searchIcon" src="@/assets/imgs/input-search.png" alt="" />
          <input ref="ipt" type="text" v-model="value" placeholder="请输入关键字搜索" />
          <img
            v-if="showClear"
            @click="clrearValueClick"
            class="clearIcon"
            src="@/assets/imgs/input-clear.png"
            alt=""
          />
        </div>
        <div class="cancelBtn" @click="handleCancel">取消</div>
      </div>
      <div class="searchBarTab">
        <van-tabs swipeable v-model="activeKey" @change="handleTabChange">
          <van-tab title="位置"></van-tab>
          <van-tab title="点位"></van-tab>
          <van-tab title="线路"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="searchResult">
      <van-list
        v-model="isLoading"
        v-if="filterList.length && value"
        :finished="filterList.length >= total"
        @load="getResultList(1)"
      >
        <van-cell v-for="item in filterList" :key="item.id">
          <div class="item-info" @click="handleListItemClick(item)">
            <div class="item-location">
              <div class="item-name" v-html="itemName(item.name)"></div>
              <div class="item-address" v-if="activeKey === 0">{{ item.address }}</div>
              <div class="item-address" v-else>
                {{ item.proAndCity }}<template v-if="item.parkName">-{{ item.parkName }}</template>
              </div>
            </div>
            <div v-if="activeKey !== 0" class="item-type">
              <span v-if="activeKey === 1">{{ item.selfTypeName }}</span>
              <span v-if="activeKey === 2">{{ item.routeTypeName }}</span>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div class="noData" v-if="noneData && value">
        <img src="@/assets/imgs/no_data.png" alt="" />
        <span>无搜索结果</span>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/index';
import { pageForLocation, pageForPOI, pageForRoute } from '@/api/api';
import bus from '@/utils/bus';

const pageRequests = [pageForLocation, pageForPOI, pageForRoute];
export default {
  computed: {
    showClear() {
      return this.value !== '';
    },
  },
  data() {
    return {
      aoiId: this.$route.query?.aoiId,
      value: '',
      filterList: [], // 筛选列表
      noData: false,
      isLoading: true,
      activeKey: 0,
      pageSize: 20,
      pageNo: 1,
      total: 0,
      noneData: false,
    };
  },
  mounted() {
    this.$refs.ipt.focus();
    // 使用防抖调后端联想查询
    this.$refs.ipt.addEventListener('input', debounce(this.getResultList, 500));
  },
  methods: {
    clrearValueClick() {
      this.value = '';
      this.filterList = [];
    },
    handleCancel() {
      this.value = '';
      this.$router.back();
    },
    handleListItemClick(item) {
      this.$store.commit('updateIsShowdetail', 0);
      bus.emit('onResetHighlightOverlay');
      bus.emit('onSelectLocation', Object.assign(item, { locationType: this.activeKey }));
      this.$router.back();
    },
    /**
     * 获取列表
     * @param {number} source 0-输入 1-滚动
     */
    getResultList(source = 0) {
      const name = this.value;
      if (source instanceof Event || source === 0) {
        this.pageNo = 1;
        this.filterList = [];
        this.total = 0;
      }
      if (source === 1) {
        this.pageNo++;
      }
      if (!this.value) return;
      // this.isLoading = true;
      pageRequests[this.activeKey]({
        name: this.value,
        aoiId: this.aoiId,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      })
        .then((res) => {
          if (name !== this.value) return; // 防止重复请求
          if (this.activeKey === 0) {
            this.filterList = res.records;
            this.total = res.records.length;
          } else {
            this.filterList = this.filterList.concat(res.records);
            this.total = res.total;
          }
          this.noneData = this.filterList.length === 0;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    itemName(item) {
      let str = this.value;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = item.replace(reg, replaceName);
      return result;
    },

    handleTabChange() {
      this.getResultList();
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPanel {
  width: 100vw;
  font-size: initial;
  display: flex;
  flex-direction: column;

  .searchBar {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    padding-top: var(--appBarHeight);
    z-index: 9;
    .searchBarInner {
      width: 100%;
      padding: 8px 8px 13px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .inputWrapper {
      flex-grow: 1;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f6f7f8;
      border-radius: 4px;

      .searchIcon {
        width: 14px;
        height: 14px;
        margin-left: 9px;
        margin-right: 8px;
      }

      input {
        height: 32px;
        flex-grow: 1;
        font-size: 16px;
        color: #000000;
        caret-color: #436eff;
        background: #f6f7f8;

        &::placeholder {
          font-size: 14px;
          color: #bfc0cb;
          font-weight: normal;
        }
      }

      .clearIcon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 7px;
        right: 8px;
      }
    }

    .cancelBtn {
      line-height: 32px;
      margin-left: 10px;
      font-size: 16px;
      color: #000000;
      height: 32px;
      width: max-content;
    }

    .searchBarTab {
      .van-tabs {
        :deep(.van-tabs__wrap) {
          .van-tab {
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(122, 123, 146, 1);
            &.van-tab--active {
              color: #436eff;
            }
          }
          .van-tabs__line {
            height: 4px;
            background-color: #436eff;
          }
        }
      }
    }
  }

  .searchResult {
    flex: 1;
    padding-top: calc(96px + var(--appBarHeight));
    box-sizing: border-box;

    .item-info {
      display: flex;
      align-items: center;
      .item-location {
        flex: 1;
        .item-name {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #000000;
        }

        .item-address {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(151, 152, 171, 1);
        }
      }
      .item-type {
        line-height: 22px;
        padding: 0 6px;
        flex-shrink: 0;
        background: #e1eafd;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0px;
        border-radius: 6px;
        color: rgba(70, 95, 253, 1);
      }
    }
    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 120px;

      img {
        width: 160px;
        height: 160px;
      }

      span {
        font-size: 16px;
        color: #222222;
      }
    }
  }
}
</style>

<template>
  <div v-if="isShowDetail === 1">
    <Bottom :isShowTool="true">
      <template v-slot:footer>
        <div class="detail">
          <div class="top">
            <div class="top-left">
              <img class="icon" :src="markerIcon" />
              <span class="title">{{ stationInfo?.name }} </span>
            </div>
            <div class="top-right" @click="closeClick">
              <img src="../../../../assets/LogisticsMap/common/close-popup.png" />
            </div>
          </div>
          <div class="content">
            <div class="content-item special">
              <div class="k">
                类型：<span class="v">{{ markerType }}</span>
              </div>
              <div class="station-type">
                点位
                <!-- {{ stationInfo?.highPrecision === 1 ? '高精点位' : '普通点位' }} -->
              </div>
            </div>
            <div class="content-item m_b_4">
              <div class="k">
                网点：<span class="v">{{ stationInfo?.parkName }}</span>
              </div>
              <!-- <div v-else class="k">
                <span>城市：</span>
                <span class="v">
                  <span v-if="stationInfo?.pro === stationInfo?.city"
                    >{{ stationInfo?.city }} {{ stationInfo?.area }}</span
                  >
                  <span v-else
                    >{{ stationInfo?.pro }} {{ stationInfo?.city }} {{ stationInfo?.area }}
                  </span>
                </span>
              </div> -->
            </div>
            <div v-show="stationInfo?.cpLabelName" class="content-item">
              <div class="k">
                所属企业：<span class="v">{{ stationInfo?.cpLabelName }}</span>
              </div>
            </div>
          </div>
          <div class="station-detail">
            <div class="station-detail-left">
              <div v-permission="['删除标注']" class="module" @click="deleteClick">
                <img src="@/assets/LogisticsMap/delete.png" />
                <span>删除</span>
              </div>
              <div v-if="stationInfo.poiType === 6" class="module" @click="contactClick">
                <img src="@/assets/LogisticsMap/contact.png" />
                <span>联系人</span>
              </div>
              <div
                v-if="stationInfo.poiType === 6"
                class="module"
                :class="{ notUploaded: !stationInfo.poiPictures.length }"
                @click="guideClick"
              >
                <img src="@/assets/LogisticsMap/guide.png" />
                <span>指引</span>
                <span v-if="!stationInfo.poiPictures.length">未上传</span>
              </div>
            </div>
            <div class="station-detail-right">
              <div
                v-if="stationInfo.poiType === 6"
                class="position"
                @click="$emit('showCalibrate')"
              >
                校准位置
              </div>
              <div v-else-if="stationInfo.poiType === 3" class="position" @click="riskInfoClick">
                风险描述
              </div>
              <div
                v-if="[3, 6].includes(stationInfo.poiType)"
                class="edit-station"
                v-permission="['编辑标注']"
                @click="editClick"
              >
                编辑
              </div>
            </div>
          </div>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import { markerType } from '@/utils/constantMap';
import { deleteStation, deleteSpacialLabel } from '@/api/api';

export default {
  components: {
    Bottom,
  },
  props: {
    stationInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isShowDetail: 'getIsShowdetail',
      authBtn: 'getAuthBtn',
    }),
    markerIcon() {
      const markerDic = markerType[this.stationInfo?.poiType] || {};
      return markerDic.circle;
    },
    markerType() {
      const markerDic = markerType[this.stationInfo?.poiType] || {};
      return markerDic.name;
    },
  },
  watch: {
    stationInfo: {
      handler(val) {
        console.log(val, val.highPrecision);
      },
      deep: true,
    },
  },
  methods: {
    closeClick() {
      this.$store.commit('updateIsShowdetail', 0);
      this.$emit('onCloseStationDetail');
    },
    async deleteClick() {
      const result = await NDialog.confirm({
        title: '确定删除该数据吗？',
        message: '数据删除可能影响线上业务，请谨慎操作',
      });
      if (result === 'confirm') {
        let res = await deleteStation({
          id: this.stationInfo.id,
        });
        if (res.code === '10000') {
          this.$toast('操作成功');
          this.$emit('onDeleteStation');
        } else {
          this.$toast(res.msg);
        }
      }
    },
    editClick() {
      this.closeClick();
      bus.emit('onEditStation', this.stationInfo);
    },

    riskInfoClick() {
      this.$router.push({
        name: 'imageUpload',
        query: { stationId: this.stationInfo.id, stationName: this.stationInfo.name },
      });
    },
    // 联系人
    contactClick() {
      window.location.href = `neolix://contacts?station=${this.stationInfo.id},${this.stationInfo.name}`;
    },
    // 指引
    guideClick() {
      this.$router.push({
        name: 'parkingGuide',
        query: this.stationInfo,
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left {
      flex: 1;
      display: flex;
      align-items: center;
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
    .top-right {
      display: flex;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .content {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(246, 247, 248, 1);
    margin: 14px 0 12px;
    .content-item {
      display: flex;
      font-size: 14px;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /* 这里是超出几行省略 */
      overflow: hidden;
      .k {
        color: rgba(156, 157, 169, 1);
      }
      .v {
        color: rgba(0, 0, 0, 1);
      }
    }
    .m_b_4 {
      margin-bottom: 4px;
    }
    .special {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .station-type {
        font-size: 12px;
        border-radius: 6px;
        padding: 3px 6px;
        background: rgba(225, 234, 253, 1);
        color: rgba(70, 95, 253, 1);
        font-weight: 600;
        border-radius: 6px;
      }
    }
  }
  .station-detail {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      .module {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2px 6px;
        img {
          width: 21px;
          margin-bottom: 1px;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }
        & + .module {
          margin-left: 8px;
        }

        &.notUploaded {
          margin-bottom: -10px;
          background: linear-gradient(
            180deg,
            rgba(255, 224, 230, 0) 0,
            rgba(255, 222, 227, 1) 100%
          );
          span + span {
            font-size: 8px;
            line-height: 10px;
            color: rgba(255, 45, 85, 1);
          }
        }
      }
    }
    &-right {
      display: flex;
      .position {
        width: 86px;
        height: 34px;
        border-radius: 79px;
        border: 1px solid rgba(70, 95, 253, 1);
        font-size: 14px;
        font-weight: 600;
        color: rgba(70, 95, 253, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
      }
      .edit-station {
        width: 86px;
        height: 34px;
        border-radius: 79px;
        background: rgba(70, 95, 253, 1);
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
